export const CURRENCIES = {
  RUB: '₽',
  EUR: '€',
  USD: '$',
  TZS: 'S',
  KZT: '₸',
  BYN: 'BYN',
  AED: 'AED',
  GEL: '₾',
}

export const APPS_URLS = {
  apple: 'https://apps.apple.com/app/mayber-horeca/id1460403681',
  google:
    'https://play.google.com/store/apps/details?id=ru.rosst.mayber&pcampaignid=fdl_long',
}

export const CREATE_ORDER_HELPER_DELAY = 10000 // 10 sec

export const ADDITIONAL_PAYMENTS_KEYS = {
  MAYBER_FEE: 'mayber_fee',
  SERVICE_FEE: 'service_fee',
  DELIVERY: 'delivery',
}

export const IGNORE_ADDITIONAL_PAYMENTS_KEYS = new Set([
  ADDITIONAL_PAYMENTS_KEYS.DELIVERY,
  ADDITIONAL_PAYMENTS_KEYS.SERVICE_FEE,
])

export const BANK_CARDS_SYSTEMS = {
  AMERICAN_EXPRESS: 'amex',
  DINERS_CLUB: 'dinc',
  DISCOVER: 'discover',
  JCB_INTERNATIONAL: 'jcb',
  MAESTRO: 'maestro',
  MASTER_CARD: 'mastercard',
  MIR: 'mir',
  UNION_PAY: 'unionpay',
  VISA: 'visa',
  UEK: 'uek',
}

export const BANK_CARDS_SYSTEMS_MAP = {
  2: BANK_CARDS_SYSTEMS.MIR,
  3: BANK_CARDS_SYSTEMS.AMERICAN_EXPRESS,
  4: BANK_CARDS_SYSTEMS.VISA,
  5: BANK_CARDS_SYSTEMS.MASTER_CARD,
  6: BANK_CARDS_SYSTEMS.MAESTRO,
  7: BANK_CARDS_SYSTEMS.UEK,
  30: BANK_CARDS_SYSTEMS.DINERS_CLUB,
  31: BANK_CARDS_SYSTEMS.JCB_INTERNATIONAL,
  34: BANK_CARDS_SYSTEMS.AMERICAN_EXPRESS,
  35: BANK_CARDS_SYSTEMS.JCB_INTERNATIONAL,
  36: BANK_CARDS_SYSTEMS.DINERS_CLUB,
  37: BANK_CARDS_SYSTEMS.AMERICAN_EXPRESS,
  38: BANK_CARDS_SYSTEMS.DINERS_CLUB,
  50: BANK_CARDS_SYSTEMS.MAESTRO,
  51: BANK_CARDS_SYSTEMS.MASTER_CARD,
  52: BANK_CARDS_SYSTEMS.MASTER_CARD,
  53: BANK_CARDS_SYSTEMS.MASTER_CARD,
  54: BANK_CARDS_SYSTEMS.MASTER_CARD,
  55: BANK_CARDS_SYSTEMS.MASTER_CARD,
  56: BANK_CARDS_SYSTEMS.MAESTRO,
  57: BANK_CARDS_SYSTEMS.MAESTRO,
  58: BANK_CARDS_SYSTEMS.MAESTRO,
  60: BANK_CARDS_SYSTEMS.DISCOVER,
  62: BANK_CARDS_SYSTEMS.UNION_PAY,
  63: BANK_CARDS_SYSTEMS.MAESTRO,
  67: BANK_CARDS_SYSTEMS.MAESTRO,
}

export const BANK_CARDS_IMAGES_URLS = {
  amex: '/img/paymentCards/amex.svg',
  discover: '/img/paymentCards/discover.svg',
  jcb: '/img/paymentCards/jcb.svg',
  maestro: '/img/paymentCards/maestro.svg',
  mastercard: '/img/paymentCards/mastercard.svg',
  mir: '/img/paymentCards/mir.svg',
  visa: '/img/paymentCards/visa.svg',
  unionpay: '/img/paymentCards/union.svg',
  DEFAULT: '/img/paymentCards/bank-card-default.svg',
}

export const NEW_ORDERS_TIMEOUT = 5000;

export const TERMS_OF_USE_URL = 'https://mayber.com/terms-of-use';

export const PRIVACY_POLICY_URL = 'https://mayber.com/privacy-policy';

export const TERMS_OF_USE_URL_GEORGIAN = 'https://mayber.com/geo/terms-of-use/en/terms_of_use_geo.pdf';
export const PRIVACY_POLICY_URL_GEORGIAN = 'https://mayber.com/geo/privacy-policy/en/privacy_policy_geo.pdf';
