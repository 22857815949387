import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as DB from './init/firebase'
import './assets/css/base.scss';
import './assets/css/flexbox.scss';
import utils from './init/utils'
import iosAlertView from 'vue-ios-alertview'
import VueSwing from 'vue-swing'
import $_bus from './bus.js'
import VueVirtualScroller from 'vue-virtual-scroller'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import OrdersStore from '@/classes/OrdersStore/OrdersStore.js'
import Places from '@/classes/Places.js'
import {CURRENCIES} from '@/consts.js'

window.places = []

Vue.use(VueVirtualScroller)

Vue.use(iosAlertView)

import VueSplide from '@splidejs/vue-splide';

Vue.use( VueSplide );

// initialize the vuex-i18 module
// import vuexI18n from 'vuex-i18n';
import VueI18n from 'vue-i18n'
//
Vue.use(VueI18n)

// Vue.use(vuexI18n.plugin, store);

// import predefined localizations
import translationsEn from './i18n/en.json';
import translationsGeo from './i18n/ka.json';
import translationsRu from './i18n/ru.json';
import iso2Map from '@/assets/data/iso2map.json'

// add translations
// Vue.i18n.add('en', translationsEn);
// Vue.i18n.add('ru', translationsRu);
const [locale] = (window.navigator?.language || 'en-En').split('-')
// test georgian
// const locale = 'ka'

const iso2Str = iso2Map[locale]?.iso2 || 'eng'
const iso3 = iso2Str.split('/')[0] || 'eng'
// default locale is english
// Vue.i18n.set(lang)
const i18n = new VueI18n({
  locale, // установка локализации по умолчанию
  messages: {
    en: translationsEn,
    ka: translationsGeo,
    ru: translationsRu
  }, // установка сообщений локализаций
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n экземпляра, так что свойство locale также существует здесь

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  }
})
DB.auth().languageCode = locale
Vue.component('vue-swing', VueSwing)

import Loader from './components/UI/Loader'

Vue.component("Loader", Loader);


Vue.config.productionTip = false
const globals = ['DB', 'utils', 'bus', 'uuid', 'axios','API_URL', 'API_PHOTO_URL', 'PAYMENTS_API_URL', 'lang', 'iso3Lang', 'PS', 'checkins', 'HTTP']

globals.forEach((item) => {
  Object.defineProperty(Vue.prototype, '$' + item, {
      get() {
          return this.$root[item] || this.$root.$options.static[item]
      }
  })
})

const API_URL = (alias = '') => {
  return `${process.env['VUE_APP_API_URL']}${alias}`
}

const PS = new Places({ DB, maps: window.google?.maps })
window.PS = PS
window.ordersStore = new OrdersStore({ DB, userUid: '', axios, currencies: CURRENCIES, $API_URL: API_URL, uuid: uuidv4, bus: $_bus })
if (!sessionStorage.getItem('comefrom')) {
  sessionStorage.setItem('comefrom', document.referrer)
}
function setMaps() {
  const maps = window.google?.maps
  if (maps) {
    PS.setGMaps(maps)
  } else {
    setTimeout(setMaps, 50)
  }
}

setTimeout(setMaps, 100)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  static: {
    axios,
  },
  data: {
    DB,
    PS,
    utils,
    bus: $_bus,
    uuid: uuidv4,
    lang: locale,
    iso3Lang: iso3,
    checkins: ordersStore.checkins,
    topUserIndexes: {},
    API_URL,
    PAYMENTS_API_URL(alias = '') {
      return `${process.env['VUE_APP_PAYMENTS_API_URL']}${alias}`
    },
    API_PHOTO_URL(alias = '') {
      return `${process.env['VUE_APP_API_PHOTO_URL']}${alias}`
    }
  },
  computed: {
    startApplication() {
      return this.$store.state.global.startApplication
    },
    user() {
      return this.$store.state.user.user
    },
    userInited() {
      return this.$store.state.user.userInited
    },
    userUid () {
        const user = this.user
        return user?.uid
    }
  },
  mounted() {
    let br = ''
    if (/Chrome/i.test(navigator.userAgent)) {
      br = 'chrome'
    } else {
      br = 'safari'
    }
    document.body.classList.add(br)
    DB.auth().onAuthStateChanged(this.authStatusChanged)
    this.$bus.$on('AUTH/update-token', this.onTokenNeedUpdate)
  },
  watch: {
    startApplication: {
      immediate: true,
      handler: 'initApplication'
    },
    userUid: {
      immediate: true,
      handler: 'initCheckins'
    }
  },
  methods: {
    onTokenNeedUpdate: async function () {
      const token = await this.setToken()
      if (!token) {
        setTimeout(this.onTokenNeedUpdate.bind(this), 250)
      } else {
        this.$bus.$emit('AUTH/tokenUpdated')
      }
    },
    initCheckins (user_uid) {
      window.ordersStore.changeUserUid(user_uid)
    },
    userLogin: async function ({displayName='', email='', emailVerified=false, uid}, term_of_use_read=false) {

      if (!uid || !term_of_use_read) return
      const userData = {
        term_of_use_read,
          user: {
            email: (emailVerified) ? email : '',
            full_name: displayName,
            gender: '',
            is_birth_date_hidden: false,
            is_star_sign_hidden: false,
            last_name: '',
            nick_name: '',
            phone: '',
            photos: [],
            profile_image_url: '',
            region: '',
            show_age_from: 0,
            show_age_to: 0,
            show_inside_only: true,
            show_me: '',
            uid,
            visibility: ''
          }
        }
      try {
        const newUser = await axios.post(this.$API_URL('/users/login'), userData)
        if (newUser) {
          return uid
        }
      } catch(err) {
        const eStatus = err.response.status
        if (eStatus === 401) {
					await this.$bus.updateToken(this.userLogin.bind(this), {displayName, email, emailVerified, uid}, term_of_use_read)
				} else {
					return false
				}
      }
    },
    callTerms() {
      return new Promise((resolve) => {
        this.$bus.$emit('openCloseTerms', resolve)
      })
    },
    authAnonimus() {
      DB.auth().signInAnonymously()
    },  
    setToken: async function(fake) {
      const user = this.DB.auth().currentUser
      let token = ''
      if (user) {
        token = await this.DB.auth().currentUser.getIdToken(true)
        this.$axios.defaults.headers.common['X-Auth-Token'] = fake || token
      }
      return token
    },
    authStatusChanged: async function (auth) {
      if (auth) {
        const uid = auth.uid,
          idToken = await this.setToken()
          window.setToken = this.setToken

          let [user] = (await DB.db.collection('users').where('uid', '==', uid).get()).docs.map(doc => {
            const user2 = Object.freeze(doc.data())
            return user2
          })
          if (!user) {
            let termsAccept = true
            // const termsAccept = await this.callTerms()
            const login = await this.userLogin(auth, termsAccept)
            if (login) {
              user = (await DB.db.collection('users').where('uid', '==', uid).get()).docs.map(doc => {
                const user2 = Object.freeze(doc.data())
                return user2
              })[0]
            } else {
              await axios.post(this.$API_URL('/users/delete'),  {
                uid
              })
              this.$DB.auth().signOut()
              return
            }
          }
          // console.log('user',auth)
          this.$store.commit('user/INIT_USER', {idToken, user: auth})
          this.$bus.$emit('openCloseAuth', {open: false})
          window.user = DB.auth().currentUser
        /*} else {
          // login ANONIMUS user
          console.log('ANONIMUS {idToken, auth}', idToken, auth)
          this.$store.commit('user/INIT_USER', {idToken, user: auth})
          this.$bus.$emit('openCloseAuth', {open: false})
          window.user = DB.auth().currentUser
        }*/
       
        
      } else {
        this.authAnonimus()
        // this.$store.commit('user/INIT_USER', {idToken: null, user: null})
        // this.$bus.$emit('openCloseAuth', {open: false})
      }
    },
    initApplication: async function (init=false) {
      if (!init) return
      try {
        this.$store.commit('user/INIT_ORDER')
      } catch (e) {
        console.log('local storage avalible!')
      }
      const topUsers = (await this.$DB.db.collection('ratings').orderBy('rating', 'desc').limit(500).get()).docs.map(doc => Object.freeze(doc.data()))
      const topUserIndexes = {}
      topUsers.forEach(({user_uid}, index) => {
        topUserIndexes[user_uid] = index+1
      })
      this.$set(this, 'topUserIndexes', topUserIndexes)
      const userGeo = await this.getUserPosition()
      await this.$PS.getShortPlaces()
      this.$store.commit('places/SET_PLACES', [])
    },
    getUserPosition: async function () {
      let newGeo = {}
      try {
        newGeo = await this.$utils.getUserGeo()
        this.$store.commit('user/SET_USER_LOCATION', newGeo)
        this.$PS.setUserGeo(newGeo)
      } catch (e) {
        this.$iosAlert(this.$t('allowYourLocation'))
          .then(function(){
            console.log('alert')
          });
      }
      return newGeo
    },
    async HTTP(type = 'get', url, data) {
      try {
        const response = await this.$axios[type](url, data)
        return response.data
      } catch (error) {
        const eStatus = error?.response?.status;
        if (eStatus === 401) {
            return await this.$bus.updateToken(
                this.HTTP.bind(this),
                type,
                url,
                data
            )
        } else {
          Promise.reject(error)
        }
      }
    }
  }
}).$mount('#app')
