<template>
  <div class="qr-tips">
    <QrPresetDrawer
      :title="$t('qrOrder.tips')"
      :currencySymbol="currencySymbol"
      :presets="tipsPresets"
      :topPriceTitle="$t('qrOrder.yourPart')"
      :topPriceAmount="yourPart"
      :loading="isApibusy"
      @select="onAmountSelect"
      @close="onDrawerClose"
    />
  </div>
</template>

<script>
import {calculateTips as calculateTipsApi, setTipPercent} from '@/init/api'
import QrPresetDrawer from './QrPresetDrawer'
const TIPS_PERCENTS = [10, 15, 20]

export default {
  props: {
    currencySymbol: {
      type: String,
      default: '',
    },
    placeUid: {
      type: String,
      required: true
    },
    checkinUid: {
      type: String,
      required: true
    },
    objectUid: {
      type: String,
      required: true
    },
    itemsToPay: {
      type: Array,
      default: () => []
    },
    userUid: {
      type: String,
      required: true,
    },
  },
  components: {
    QrPresetDrawer,
  },
  data() {
    return {
      currentPresetIndex: 1,
      isApibusy: false,
      tipsPresets: [],
      yourPart: '0'
    }
  },
  computed: {
    sortedItemsToPayUids() {
      return this.itemsToPay.map((itemToPay) => itemToPay.uid).sort((a, b) => a.localeCompare(b)).join('')
    },
    currentPlace() {
      return this.$PS.data.find((place) => place.uid === this.placeUid)
    }
  },
  watch: {
    sortedItemsToPayUids: {
      immediate: true,
      handler: 'calculateTipsLocal',
    },
  },
  methods: {
    selectPreset(index) {
      this.currentPresetIndex = index
    },
    async onAmountSelect(amount) {
      const value = this.tipsPresets.find((tips) => tips.value === amount)?.value || amount;
      this.$emit('tipsDrawerClose', value)
    },
    onDrawerClose() {
      this.$emit('tipsDrawerClose')
    },
    setYourPart(fees = []) {
      this.yourPart = fees.reduce((accumulator, fee) => {
        return accumulator+= Number(fee)
      }, 0).toFixed(0)
    },
    emitCalculated(mayberFee = 0, serviceFee = 0, total = 0) {
      this.$emit('calculated', {
          mayberFee,
          serviceFee,
          total
        })
    },
    getTipsPresets(totalPrice) {
      return TIPS_PERCENTS.map(percent => ({
        title: `${percent}%`,
        percent,
        value: Math.round((totalPrice * percent) / 100)
      }))
    },
    getYourPart(itemsToPay) {
      return itemsToPay.reduce((sum, dish) => sum + Number(dish.price), 0);
    },
    //getUnpai
    calculateTipsLocal() {
      if (!this.itemsToPay.length) {
        this.tipsPresets = []
        this.emitCalculated()
        return;
      }
      
      const yourPart = this.getYourPart(this.itemsToPay)
      this.tipsPresets = this.getTipsPresets(yourPart)
      this.setYourPart([0, 0, yourPart])
      this.emitCalculated(0, Number(this.currentPlace.service_fee_percent), yourPart)
    },
    async calculateTips() {
      if (!this.itemsToPay.length) {
        this.tipsPresets = []
        this.emitCalculated()
        return;
      }

      try {
        this.isApibusy = true
        const {tips, mayber_fee, service_fee, total} = await calculateTipsApi(this.placeUid, this.objectUid, this.itemsToPay.map((item) => item.uid))
     
        let preparedTips = []
        if (!Array.isArray(tips)) {
          preparedTips = Object.entries(tips).map(([key, value]) => ({
            title: key,
            value: Number(value),
            percent: parseInt(key)
          }))
        } else {
          preparedTips = tips
        }
        this.tipsPresets = preparedTips
        this.setYourPart([mayber_fee, service_fee, total])
        this.emitCalculated(Number(mayber_fee), Number(service_fee), Number(total))
      } catch (ex) {
        this.emitCalculated()
        console.error(ex)
      } finally {
        this.isApibusy = false
      }
    },
    async setTipsPersents(percents) {
      try {
        this.isApibusy = true
        await setTipPercent(this.checkinUid, this.placeUid, this.userUid, percents)
      } catch (ex) {
        console.error(ex)
      } finally {
        this.isApibusy = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
</style>
