(function() {
	/**
	 * Корректировка округления десятичных дробей.
	 *
	 * @param {String}  type  Тип корректировки.
	 * @param {Number}  value Число.
	 * @param {Integer} exp   Показатель степени (десятичный логарифм основания корректировки).
	 * @returns {Number} Скорректированное значение.
	 */
	const roundTo = (value, exp, method) => {
		if (!Number.isFinite(value) || !Number.isInteger(exp)) {
			return NaN;
		}
		
		const factor = Math.pow(10, exp);
		return Math[method](value * factor) / factor;
	};
	
	// Десятичное округление к ближайшему
	if (!Math.round10) {
	  Math.round10 = (value, exp) => roundTo(value, exp, 'round');
	}
	// Десятичное округление вниз
	if (!Math.floor10) {
	  Math.floor10 = (value, exp) => roundTo(value, exp, 'floor');
	}
	// Десятичное округление вверх
	if (!Math.ceil10) {
	  Math.ceil10 = (value, exp) => roundTo(value, exp, 'ceil');
	}
})()
export default {
	getUserGeo: () => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				function ({ coords }) {
					resolve({
						lat: coords.latitude,
						lng: coords.longitude
					})
				},
				function (err) {
					reject(err)
				}, 
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0
				}
			)
		})
	},
	validateEmail(email) {
		return String(email)
			.toLowerCase()
			.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
	}
}