<template>
  <div class="qr-item" @click="onItemSelect">
    <div class="qr-item__image-wrapper">
      <img class="qr-item__image" :src="image" />
    </div>
    <div class="qr-item__info">
      <div class="qr-item__title">{{ item.title }}</div>
      <div class="qr-item__price">{{ currencySymbol }} {{ item.price }}</div>
    </div>
    <template v-if="isDisabled">
      <div class="qr-item__disabled-shield" />
      <div v-if="isLocked" key="locked" class="qr-item__paid qr-item__paid--locked">
        {{ $t('qrOrder.paymentInProgress') }}
        <img src="/img/paymentLockIcon.svg" alt="lock" />
      </div>
      <div v-else key="disabled" class="qr-item__paid">
        {{ $t('qrOrder.paid') }}
      </div>
    </template>
    <div v-else-if="isSplitMode">
      <div v-if="!isSelected" class="qr-item__disabled-shield" />
      <div class="qr-item__checkbox">
        <QrCheckbox :checked="isSelected" @change="onItemSelect" />
      </div>
    </div>
  </div>
</template>

<script>
import QrCheckbox from './QrCheckbox'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSplitMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    QrCheckbox,
  },
  computed: {
    image() {
      return this.item.photo_uri || '/img/restaurantPlaceholder.jpg'
    },
    isLocked() {
      return this.item.locked
    },
    isDisabled() {
      return this.isLocked || this.item.paid
    },
  },
  methods: {
    onItemSelect() {
      if (this.isDisabled) {
        return
      }

      this.$emit('select')
    },
  },
}
</script>

<style lang="scss">
.qr-item {
  height: 102px;
  width: 100%;
  @include flex(row, flex-start, center);
  background: #1a1a1a;
  position: relative;

  &__image-wrapper {
    width: 144px;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    @include flex(column, flex-start, flex-start);
    gap: 10px;
    width: calc(100% - 144px);
    padding: 0 16px;
  }

  &__title {
    @include font(18, 21, 400);
    text-align: left;
    color: $white;
  }

  &__price {
    @include font(18, 21, 500);
    color: $goldenMain;
  }

  &__paid {
    position: absolute;
    bottom: 14px;
    right: 16px;
    border-radius: 6px;
    background: #333333;
    gap: 10px;
    padding: 3px 10px;
    @include font(12, 14, 400);
    color: $white;
    z-index: 2;

    &--locked {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 1);
      background: linear-gradient(90deg, #574968 0%, #684164 100%);
      padding: 4px 10px;
      color: rgba(184, 161, 94, 1);
      @include font(12, 14, 600);
      @include flex(row, center, center);
      gap: 10px;
    }
  }

  &__checkbox {
    position: absolute;
    bottom: 14px;
    right: 16px;
    z-index: 2;
  }

  &__disabled-shield {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1a1a1a;
    opacity: 0.6;
    z-index: 1;
  }
}
</style>
